/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { useSearchParams } from 'next/navigation';
import { PropsWithChildren } from 'react';

import { InventoryContext } from '@/contexts/InventoryContext';
import { LayoutProvider } from '@/contexts/LayoutContext';
import { InventoryProvider } from '@lamesarv-sdk/inventory';
import { getFiltersFromSearchParams } from '@lamesarv-sdk/tools';
import { IFilterBase, InventoryField, InventoryFiltersBase } from '@lamesarv-sdk/types';

interface LayoutProvidersProps {
  customEquipmentFilters: IFilterBase;
  noPriceButtonContent?: string;
  inventoryFiltersBase?: InventoryFiltersBase;
}

export const LayoutProviders = (props: PropsWithChildren<LayoutProvidersProps>) => {
  const searchParams = useSearchParams();
  const searchDto = getFiltersFromSearchParams(searchParams, false, props.inventoryFiltersBase);

  return (
    <LayoutProvider noPriceButtonContent={props.noPriceButtonContent}>
      <InventoryProvider
        {...searchDto}
        inventoryFiltersBase={props.inventoryFiltersBase}
        clientContext={InventoryContext as any}
        inventoryFiltersCustom={{
          [InventoryField.equipment]: props.customEquipmentFilters,
        }}
      >
        {props.children as any}
      </InventoryProvider>
    </LayoutProvider>
  );
};
